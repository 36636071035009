import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { END } from 'redux-saga';

// import intermediario from 'assets/banner-intermediario.png';
// import Caixa from 'assets/banner-tarja/caixa-banner-tarja.png';
import widgetRoleta from 'assets/roleta/widget-roleta-pets.png';
// import { BannerTarja } from 'components/common/banner-tarja';
// import { CategoriesComponent } from 'components/common/categories';
import Modal from 'components/common/Modal';
import { Roulette } from 'components/common/Roulette';
import SEO from 'components/common/SEO';
import Layout from 'components/Layout';
// import { PixComponent } from 'components/pages/Checkout/Confirmation/Pix';
import { IState, SagaStore, storeWrapper } from 'store';
import { ICartProps } from 'store/modules/cart';
import { IConfigApplicationProps } from 'store/modules/config';
import { loadHomeData, IHomeDataState } from 'store/modules/home';
import {
  GAEvent,
  getGAViewPromotion,
  getGAViewItemsHomeList,
} from 'utils/gtag';
import {
  Container,
  Content,
  ComponentSection,
  TitleSection,
} from 'styles/pages/App/Home';

const Banner = dynamic(
  () => import('components/common/Templates/Carousel/Banner'),
);

const Mosaic = dynamic(
  () => import('components/common/Templates/Carousel/Mosaic'),
);
const MultiSliderBrands = dynamic(
  () => import('components/common/Templates/Carousel/MultiSliderBrands'),
);
const ProductCarousel = dynamic(
  () => import('components/common/Templates/Carousel/ProductCarousel'),
);
const SimpleCarousel = dynamic(
  () => import('components/common/Templates/Carousel/SimpleCarousel'),
);
const Grid = dynamic(() => import('components/common/Templates/Grid'));

const Timer = dynamic(() => import('components/common/Timer'));

const RouletteModal = dynamic(() => import('components/common/RouletteModal'));

export default function Home() {
  const { components, banners } = useSelector<IState, IHomeDataState>(
    state => state.homeData,
  );

  const config = useSelector<IState, IConfigApplicationProps>(
    state => state.config.config,
  );

  const router = useRouter();

  useEffect(() => {
    if (!router.query?.campanha) {
      Cookies.remove('@Pet:campanha');
    }
  }, [router]);

  useEffect(() => {
    setTimeout(() => {
      if (components.length) {
        components.map(component => {
          if (component.tipo === 'PRODUTO')
            GAEvent({
              action: 'view_item_list',
              data: getGAViewItemsHomeList({
                products: component.itens,
                itemListId: `HOME - ${component.titulo}`,
                itemListName: `HOME - ${component.titulo}`,
              }),
            });
        });
      }
    }, 10000);

    Cookies.set('@Pet:itemList', 'HOME');
  }, [components]);

  return (
    <Layout>
      <Container>
        <SEO title="">
          <meta name="cs:page" content="home" />
        </SEO>

        <Timer />

        {!!banners?.length && <Banner banners={banners} />}

        <Content>
          {/* <BannerTarja /> */}
          {/* <CategoriesComponent /> */}

          <Content>
            {components &&
              components.map(component => {
                return (
                  <ComponentSection key={component.ordem}>
                    {component.exibirTitulo && (
                      <TitleSection>{component.titulo}</TitleSection>
                    )}

                    {component.tipo === 'MARCA' && (
                      <MultiSliderBrands items={component.itens} />
                    )}

                    {component.tipo === 'BANNER' && (
                      <SimpleCarousel items={component.itens} />
                    )}

                    {component.tipo === 'PRODUTO' &&
                      component.template === 'CARROSSEL' && (
                        <ProductCarousel
                          items={component.itens}
                          componenteVitrineId={component.componenteVitrineId}
                        />
                      )}

                    {component.tipo === 'PRODUTO' &&
                      component.template === 'GRADE' && (
                        <Grid
                          items={component.itens}
                          componenteVitrineId={component.componenteVitrineId}
                        />
                      )}

                    {component.tipo === 'PRODUTO' &&
                      (component.template === 'MOSAICO_LTR' ||
                        component.template === 'MOSAICO_RTL') && (
                        <Mosaic
                          items={component.itens}
                          banner={{
                            bannerImagemUrl: component.bannerImagemUrl,
                            bannerLinkUrl: component.bannerLinkUrl,
                          }}
                          template={component.template}
                          componenteVitrineId={component.componenteVitrineId}
                        />
                      )}
                  </ComponentSection>
                );
              })}
          </Content>
        </Content>
      </Container>
      {config.roletaSorte?.ativo && config.roletaSorte?.exibirHome && (
        <RouletteModal fromHome />
      )}
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps =
  storeWrapper.getServerSideProps(async ({ query, store, res }) => {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59',
    );

    const { vitrineId } = query;

    store.dispatch(loadHomeData(vitrineId ? String(vitrineId) : null));
    store.dispatch(END);

    await (store as SagaStore).sagaTask.toPromise();

    return {
      props: {},
    };
  });
