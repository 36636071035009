import styled from 'styled-components';

import { colors, metrics } from '../..';

export const Container = styled.div`
  width: 100%;

  .banner-intermediario {
    max-width: 1400px;
    margin: 20px auto;
    aspect-ratio: 2760/300;
    width: 100%;
  }

  @media only screen and (max-width: 1400px) {
    .banner-intermediario {
      margin: 17px 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    .banner-intermediario {
      margin: 15px 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    .banner-intermediario {
      margin: 12px 0;
    }
  }

  @media only screen and (max-width: 500px) {
    .banner-intermediario {
      margin: 10px 0;
    }
  }
`;

export const Banner = styled.div`
  img {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${metrics.container_max_width};
  padding: 0 3.6rem;
  height: max-content;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding: 0 1.2rem;
  }
`;

export const ComponentSection = styled.section`
  width: 100%;

  & + * {
    margin-top: 3.6rem;
  }

  @media only screen and (max-width: 500px) {
    & + * {
      margin-top: 2rem;
    }
  }
`;

export const TitleSection = styled.h2`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
  font-size: 22px;
  color: var(--primary-color);
  text-transform: uppercase;

  img {
    max-width: 30px;
  }

  .title {
    font-size: 25px;
    color: #da466a;
  }

  @media only screen and (max-width: 1600px) {
    margin-bottom: 27px;

    img {
      max-width: 27px;
    }

    .title {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 1400px) {
    margin-bottom: 25px;

    img {
      max-width: 25px;
    }

    .title {
      font-size: 23px;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-bottom: 23px;

    img {
      max-width: 23px;
    }

    .title {
      font-size: 22px;
    }
  }

  @media only screen and (max-width: 1024px) {
    margin-bottom: 21px;

    img {
      max-width: 21px;
    }

    .title {
      font-size: 21px;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 19px;

    img {
      max-width: 19px;
    }

    .title {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 500px) {
    margin-bottom: 15px;

    img {
      max-width: 17px;
    }

    .title {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 400px) {
    margin-bottom: 10px;

    img {
      max-width: 15px;
    }

    .title {
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 350px) {
    margin-bottom: 10px;

    img {
      max-width: 13px;
    }

    .title {
      font-size: 13px;
    }
  }
`;
