import React from 'react';

import Head from 'next/head';
import { useSelector } from 'react-redux';

import { IState } from 'store';
import { IConfigApplicationProps } from 'store/modules/config';
import { objectIsEmpty } from 'utils/objectEmpty';

interface ISEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  imageSEO?: string;
}

const SEO: React.FC<ISEOProps> = ({
  title: titleSEO,
  description: descriptionSEO,
  keywords: keywordsSEO,
  imageSEO,
  children,
}) => {
  const isHMG = process.env.NEXT_PUBLIC_HOMOLOGACAO === 'true';
  const config = useSelector<IState, IConfigApplicationProps>(
    state => state.config.config,
  );

  if (!config || objectIsEmpty(config)) {
    return null;
  }

  const {
    aparencia: { icone: icon, logo: image, openGraph },
    loja: { nome, seo_Titulo, seo_Descricao, seo_PalavrasChaves },
  } = config;

  const title = titleSEO || seo_Titulo;
  const keywords = keywordsSEO || seo_PalavrasChaves;
  const description = descriptionSEO || seo_Descricao;

  return (
    <Head>
      <title>
        {title && `${title}`}
        {title != nome ? title && nome && `${' - '}` : null}
        {title != nome ? nome : null}
      </title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="image" content={imageSEO || image} />
      <link rel="shortcut icon" href={icon} type="image/png" />

      {children}

      {isHMG ? (
        <meta name="robots" content="noindex,nofollow" />
      ) : (
        <meta name="robots" content="index,follow" />
      )}

      <meta name="language" content="pt-BR" />
      <meta name="country" content="BRA" />
      <meta name="currency" content="R$" />
      <meta name="copyright" content="Leanwork Tecnologia LTDA" />
      <meta httpEquiv="x-ua-compatible" content="IE=edge,chrome=1" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="MobileOptimized" content="320" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="theme-color" content="#fff" />
      <meta name="msapplication-TileColor" content="#fff" />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta name="google" content="notranslate" />
      <meta name="author" content={nome} />

      <meta property="og:title" content={title ? `${title} - ${nome}` : nome} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:type" content="website" />
      <meta
        property="og:site_name"
        content={title ? `${title} - ${nome}` : nome}
      />
      <meta property="og:image" content={imageSEO || openGraph || image} />
      <meta
        property="og:image:secure_url"
        content={imageSEO || openGraph || image}
      />
      <meta property="og:image:alt" content="Thumbnail" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Head>
  );
};

export default SEO;
